*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body {
  overflow-x: hidden;
}
button {
  border-radius: 50px !important;
}
a {
  font-family: "Medium";
  text-decoration: none;
  color: black;
}

.linkCard {
  transition: 0.6s ease-in-out;
}
.linkCard :hover {
  transform: scale(1.02);
}
.container {
  max-width: 1280px;
  padding-inline: 1rem;
  margin-inline: auto;
}
.img-max-wd-8 {
  max-width: 8rem;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.map {
  width: 100%;
  height: 20vh;
}
