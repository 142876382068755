@font-face {
  font-family: "Regular";
  src: local("EBGaramond-Regular"),
    url("./EBGaramond-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Italic";
  src: local("EBGaramond-Italic"),
    url("EBGaramond-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";
  src: local("EBGaramond-Medium"),
    url("EBGaramond-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "MediumItalic";
  src: local("EBGaramond-MediumItalic"),
    url("EBGaramond-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Bold";
  src: local("EBGaramond-Bold"), url("./EBGaramond-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BoldItalic";
  src: local("EBGaramond-BoldItalic"),
    url("EBGaramond-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "SemiBold";
  src: local("EBGaramond-SemiBold"),
    url("./EBGaramond-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "SemiBoldItalic";
  src: local("EBGaramond-SemiBoldItalic"),
    url("EBGaramond-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ExtraBold";
  src: local("EBGaramond-ExtraBold"),
    url("./EBGaramond-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "ExtraBoldItalic";
  src: local("EBGaramond-ExtraBoldItalic"),
    url("EBGaramond-ExtraBoldItalic.ttf") format("truetype");
}
